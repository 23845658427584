@import 'config/variables';
@import 'components/image-teaser/functions/get-gradient-angle';

@mixin teaser-gradient-overlay(
    $x: 1,
    $y: 1,
    $color: $color_background-700,
    $gradient-range: 85%
) {
    background: linear-gradient(
        get-gradient-angle($x, $y),
        $color 0%,
        rgba($color, 0) $gradient-range
    );
}
