@import 'config/variables';
@import 'config/breakpoints';
@import 'vendors/include-media';
@import 'utils/component-spacing';
@import 'etc/view.scss';
@import 'utils/deep-get/deep-get.scss';

@import 'components/image-teaser/mixins/contrast-optimizers/gradient';
@import 'components/image-teaser/mixins/contrast-optimizers/text-shadow';

@import 'components/_slider/mixin';
@import 'components/_slider/navigation/navigation.scss'; // TMP
@import 'components/_slider/pagination/pagination.scss'; // TMP

@import 'components/button/mixin';

$it_mobile-gap: 0.8rem !default;
$it_desktop-gap: 0.8rem !default;

$it--slider_mobile-gap: $it_mobile-gap !default;
$it--slider_desktop-gap: $it_desktop-gap !default;

// "next-slide-visible" will work on mobiles and browser-width desktop
// For container-width desktop it doesn't make sense.
// Please use 'use_whole_screen' option in etc/view to have next slides visible for content-width
$it--slider_next-slide-visible: 0px !default; // must be with unit (any kind)

$it_cta-type--light: 'secondary' !default;
$it_cta-type--dark: 'primary' !default;
$it_cta-font-size: 1.2rem !default;
$it_cta-icon-position: 'right' !default;
$it_cta-icon-width: 1.2rem !default;
$it_cta-icon-height: 1.4rem !default;

// contrast optimizers
$it_container-background--light: $color_background-700 !default;
$it_container-background--dark: $color_background-200 !default;
$it_gradient-color--light: $color_background-700 !default;
$it_gradient-color--dark: $color_background-200 !default;
$it_text-shadow-color--light: $color_background-700 !default;
$it_text-shadow-color--dark: $color_background-200 !default;
$it_text-shadow-offset--light: 0 0 2.5rem !default;
$it_text-shadow-offset--dark: 0 0 2.5rem !default;
$it_text-shadow-step--light: 5 !default;
$it_text-shadow-step--dark: 5 !default;

// icon component
/**
 * This components is sort of unique because it's items-per-row configuration is only available for tablet+
 * This map adds possibility to 'force' provied columns configuration up to laptop resolutions.
 */
$it--icon_items-in-view-till-laptop: deep-get(
    $view-xml,
    vars,
    MageSuite_ContentConstructorFrontend,
    icon,
    js,
    columnsConfig
) !default;

.#{$ns}image-teaser {
    $root: &;

    --slogan-font-size: 3.8rem;
    --description-font-size: 1.5rem;

    @include it-grid($m-gap: $it_mobile-gap, $d-gap: $it_desktop-gap);

    &--slider {
        @include slider(
            $m-gap: $it--slider_mobile-gap,
            $d-gap: $it--slider_desktop-gap,
            $next-visible: $it--slider_next-slide-visible,
            $root: $root
        );
    }

    &--slider-mobile:not(#{$root}--slider) {
        @include media('<tablet') {
            @include slider-mobile(
                $m-gap: $it--slider_mobile-gap,
                $next-visible: $it--slider_next-slide-visible,
                $root: $root
            );
        }

        @include media('>=tablet') {
            #{$root}__nav,
            #{$root}__pagination {
                display: none;
            }
        }
    }

    &__slide {
        &--scheme-light {
            --scheme-color: #{$scheme--light_texts-color};
            --scheme-background: #{$scheme--light_background};
        }

        &--scheme-dark {
            --scheme-color: #{$scheme--dark_texts-color};
            --scheme-background: #{$scheme--dark_background};
        }

        // teaser & text
        &:not(#{$root}__slide--image-uploaded) {
            background: var(--scheme-background);
        }

        @include media('<tablet') {
            &--image-uploaded {
                #{$root}__slides--mobile_text-image & {
                    order: 1;
                }

                #{$root}__slides--mobile_image-text & {
                    order: -1;
                }
            }
        }
    }

    // Hero in mobile as 'Large Teaser' scenario
    @include media('<tablet') {
        &--as-list-mobile {
            #{$root}__slides {
                flex-wrap: wrap;
            }

            #{$root}__slide {
                margin: 0 0 #{$it--slider_mobile-gap};
            }

            #{$root}__pagination,
            #{$root}__nav {
                display: none;
            }
        }
    }

    &__link {
        display: block;
        height: 100%;

        &,
        &:hover,
        &:visited,
        &:active,
        &:focus {
            text-decoration: none;
        }
    }

    &__figure {
        height: 100%;
        position: relative;
    }

    &__content-wrapper {
        color: var(--scheme-color);

        &--under {
            position: relative;
            z-index: 1;
            margin: -2rem 2rem 0;
            padding: 2rem;
            background-color: var(--scheme-background);
            text-align: center;
        }

        &--over {
            width: 100%;
            height: 100%;
            display: flex;
            padding: 2rem;
            overflow: hidden;
            align-items: flex-start;

            #{$root}__picture + &,
            #{$root}--teaser-and-text #{$root}__slide--image-uploaded & {
                position: absolute;
                top: 0;
                left: 0;
            }
        }

        &--content-align-x {
            &-2 {
                justify-content: center;
                text-align: center;
            }

            &-3 {
                justify-content: flex-end;
                text-align: right;
            }
        }

        &--content-align-y {
            &-2 {
                align-items: center;
            }

            &-3 {
                align-items: flex-end;
            }
        }
    }

    &__content {
        position: relative;
    }

    /*
     * Keep CTA separated from text-content for all scenarios
     * "!important" added due to fallback from --scheme-* with button() mixin that resets margins
     */
    &__text-content + #{$root}__cta {
        margin-top: 1em !important;
    }

    &__slogan {
        margin: 0;
        line-height: 1.1;
        font-size: var(--slogan-font-size);
        font-weight: 900;
        overflow-wrap: break-word;
        word-wrap: break-word;
        word-break: break-word;
        hyphens: auto;
    }

    &__description {
        margin: 1em 0 0;
        line-height: 1.1;
        font-size: var(--description-font-size);
    }

    &__cta {
        #{$root}__slide--scheme-dark & {
            @include button(
                $type: $it_cta-type--dark,
                $icon_pos: $it_cta-icon-position
            );
        }

        #{$root}__slide--scheme-light & {
            @include button(
                $type: $it_cta-type--light,
                $icon_pos: $it_cta-icon-position
            );
        }

        &-span {
            #{$root}__slide--scheme-dark & {
                @include button_span(
                    $type: $it_cta-type--dark,
                    $icon_pos: $it_cta-icon-position
                );
            }

            #{$root}__slide--scheme-light & {
                @include button_span(
                    $type: $it_cta-type--light,
                    $icon_pos: $it_cta-icon-position
                );
            }
        }

        &-icon {
            #{$root}__slide--scheme-dark & {
                @include button_icon(
                    $type: $it_cta-type--dark,
                    $icon_pos: $it_cta-icon-position
                );
            }

            #{$root}__slide--scheme-light & {
                @include button_icon(
                    $type: $it_cta-type--light,
                    $icon_pos: $it_cta-icon-position
                );
            }
        }

        display: none;

        @include media('>=phoneLg') {
            display: inline-flex;
            z-index: 1;
            margin: 1.2em 0 0;
            align-items: center;
            font-size: $it_cta-font-size;

            &-icon {
                width: $it_cta-icon-width;
                height: $it_cta-icon-height;
            }
        }
    }

    /**
     * Contrast Optimizers
     */
    // common for overlay amnd gradient
    &__overlay,
    &__gradient {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    // overlay, container
    &__overlay,
    &__optimizer-container {
        #{$root}__slide--scheme-light & {
            background-color: $it_container-background--light;
        }

        #{$root}__slide--scheme-dark & {
            background-color: $it_container-background--dark;
        }
    }

    // gradient
    &__gradient {
        @for $x from 1 through 3 {
            &--direction-x-#{$x} {
                @for $y from 1 through 3 {
                    &#{$root}__gradient--direction-y-#{$y} {
                        #{$root}__slide--scheme-light & {
                            @include teaser-gradient-overlay(
                                $x,
                                $y,
                                $it_gradient-color--light,
                                85%
                            );
                        }

                        #{$root}__slide--scheme-dark & {
                            @include teaser-gradient-overlay(
                                $x,
                                $y,
                                $it_gradient-color--dark,
                                85%
                            );
                        }
                    }
                }
            }
        }
    }

    // mirror image
    &__image {
        &--mirror {
            transform: scaleX(-1);
        }
    }

    // container
    &__content--container {
        position: relative;
        z-index: 1;
        padding: 2em;
    }

    &__optimizer-container {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
    }

    // text shadow
    &__text-content--text-shadow {
        #{$root}__slide--scheme-dark & {
            @include optimizer-text-shadow(
                $color: $it_text-shadow-color--dark,
                $text-shadow-offset: $it_text-shadow-offset--dark,
                $step: $it_text-shadow-step--dark
            );
        }

        #{$root}__slide--scheme-light & {
            @include optimizer-text-shadow(
                $color: $it_text-shadow-color--light,
                $text-shadow-offset: $it_text-shadow-offset--light,
                $step: $it_text-shadow-step--light
            );
        }
    }

    // Icon component
    &--icon {
        @include media('>=tablet', '<laptop') {
            @include slider-mobile(
                $m-gap: $it--slider_mobile-gap,
                $next-visible: $it--slider_next-slide-visible,
                $root: $root
            );
        }

        @each $breakpoint, $itemsPerRow in $it--icon_items-in-view-till-laptop {
            @include media('>=#{$breakpoint}', '<laptop') {
                --size: calc(
                    var(--base-size) / #{$itemsPerRow} - ((
                                    #{$itemsPerRow - 1} * var(--gap)
                                ) / #{$itemsPerRow})
                ) !important;
            }
        }

        @include media('>=tablet', '<laptop') {
            &#{$root}--slider-mobile:not(#{$root}--slider) {
                @media (hover: hover) and (pointer: fine) {
                    #{$root}__nav {
                        display: block;
                    }
                }

                #{$root}__pagination {
                    display: flex;
                }
            }
        }

        #{$root}__slogan {
            --slogan-font-size: 1.6rem;
        }

        #{$root}__description {
            margin-top: 0.5em;
        }

        #{$root}__cta {
            display: none;
        }

        #{$root}__content-wrapper {
            &--under {
                margin-top: 0;
                padding: 1rem;
            }

            &--cta-only {
                display: none;
            }
        }
    }

    // Mosaic component
    @include media('>=tablet') {
        &--mosaic {
            #{$root}__slides--mosaic-scenario {
                &-1 {
                    grid-template-columns: minmax(0, 2fr) minmax(0, 1fr);
                }

                &-2 {
                    grid-template-columns: minmax(0, 1fr) minmax(0, 2fr);
                }
            }
        }
    }

    // Teaser & Text component
    @include media('<tablet') {
        &--teaser-and-text {
            #{$root}__slides {
                display: flex;
                flex-wrap: wrap;
            }

            #{$root}__slide {
                flex: 0 0 100%;
            }
        }
    }
}

.#{$ns}container--image-teaser {
    @include component-spacing();
}

.#{$ns}container--grid-teaser {
    margin: 0;
}

.#{$ns}container--image-teaser-window-width {
    padding-left: 0;
    padding-right: 0;

    .#{$ns}container__inner {
        max-width: 100%;
    }
}

.#{$ns}container--image-teaser-slider {
    padding-left: 0;
    padding-right: 0;
}
