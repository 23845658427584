@import 'config/variables';
@import 'components/_slider/navigation/mixin';

.#{$ns}slider-navigation {
    display: none;

    @media (hover: hover) and (pointer: fine) {
        @include slider-navigation();
    }
}
