/**
 * Breakpoint component used by include-media vendor library.
 */
@use 'sass:map';
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
/**
* Default project breakpoints - [em]
*/
/**
 * Breakpoint component used by include-media vendor library.
 */
@use 'sass:map';
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
/**
* Default project breakpoints - [em]
*/
/**
 * Breakpoint component used by include-media vendor library.
 */
@use 'sass:map';
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
/**
* Default project breakpoints - [em]
*/
/**
 * Breakpoint component used by include-media vendor library.
 */
@use 'sass:map';
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
/**
* Default project breakpoints - [em]
*/
/**
 * Breakpoint component used by include-media vendor library.
 */
@use 'sass:map';
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
/**
* Default project breakpoints - [em]
*/
/**
 * Breakpoint component used by include-media vendor library.
 */
@use 'sass:map';
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
/**
* Default project breakpoints - [em]
*/
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
/**
 * Breakpoint component used by include-media vendor library.
 */
@use 'sass:map';
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
/**
* Default project breakpoints - [em]
*/
/**
 * Breakpoint component used by include-media vendor library.
 */
@use 'sass:map';
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
/**
* Default project breakpoints - [em]
*/
/***
 * Remove browser's margins, paddings and list style rules
 */
/***
 * Hide native browser's scrollbar
 */
/**
 * Breakpoint component used by include-media vendor library.
 */
@use 'sass:map';
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
/**
* Default project breakpoints - [em]
*/
/**
 * Breakpoint component used by include-media vendor library.
 */
@use 'sass:map';
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
/**
* Default project breakpoints - [em]
*/
.cs-slider-navigation {
  display: none; }
  @media (hover: hover) and (pointer: fine) {
    .cs-slider-navigation {
      position: absolute;
      z-index: 11;
      top: 50%;
      transform: translate3d(0, -50%, 0);
      display: flex;
      align-items: center;
      justify-content: center;
      width: 4.4rem;
      height: 5.2rem;
      border: 0;
      border-radius: 7px;
      background: #fff;
      box-shadow: 1px 1px 2px 0 rgba(0, 0, 0, 0.25);
      padding: 0;
      color: #7e57c2;
      cursor: pointer;
      transition: all 0.3s; }
      .cs-slider-navigation--prev {
        left: 1.5rem; }
      .cs-slider-navigation--next {
        right: 1.5rem; }
      .cs-slider-navigation[disabled] {
        opacity: 0.25;
        cursor: not-allowed; }
        .cs-slider-navigation[disabled] svg {
          filter: grayscale(1); }
      .cs-slider-navigation:not([disabled]):hover {
        box-shadow: 1px 1px 10px 0 rgba(0, 0, 0, 0.25); }
      .cs-slider-navigation__icon {
        width: 1.4rem;
        height: 2.7rem;
        fill: currentColor; } }

/**
 * Breakpoint component used by include-media vendor library.
 */
@use 'sass:map';
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
/**
* Default project breakpoints - [em]
*/
/**
 * Breakpoint component used by include-media vendor library.
 */
@use 'sass:map';
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
/**
* Default project breakpoints - [em]
*/
.cs-slider-pagination {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 0.8rem;
  min-height: 3rem; }
  .cs-slider-pagination--fraction {
    gap: 0;
    white-space: pre-wrap;
    color: inherit;
    font-weight: 600; }
    .cs-slider-pagination--fraction span {
      color: #11a099; }
  .cs-slider-pagination__bullet {
    position: relative;
    border-radius: 2px;
    background: #fff;
    border: 0;
    inline-size: 2.2rem;
    block-size: 0.9rem;
    transition: all 0.15s;
    overflow: hidden;
    text-indent: -99rem; }
    .cs-slider-pagination__bullet.current {
      background-color: #11a099; }
    .cs-slider-pagination__bullet:only-child {
      display: none; }

/*
 * Function to get value from a list of values, based on side
 * @param {string} $variable: variable with a list of values (like paddings, margins etc.);
 * @param {string} $side: side (up / right / down / left) for which we want to get value
 * Example:
 * $component_padding: 1rem 2rem 3rem;
 * get-value-from-list($component_padding, 'right') // 2rem
 * get-value-from-list($component_padding, 'bottom') // 3rem
 */
/* stylelint-disable block-no-empty */
/* stylelint-enable */
/**
 * This components is sort of unique because it's items-per-row configuration is only available for tablet+
 * This map adds possibility to 'force' provied columns configuration up to laptop resolutions.
 */
.cs-image-teaser {
  --slogan-font-size: 3.8rem;
  --description-font-size: 1.5rem;
  --gap: 0.8rem;
  /*
     * Keep CTA separated from text-content for all scenarios
     * "!important" added due to fallback from --scheme-* with button() mixin that resets margins
     */
  /**
     * Contrast Optimizers
     */ }
  .cs-image-teaser:not(.cs-container--use-whole-width) .cs-image-teaser.cs-image-teaser--container-width .cs-image-teaser__slides-wrapper {
    max-width: 128rem;
    margin-left: auto;
    margin-right: auto; }
  .cs-image-teaser__slides {
    --columns: 1;
    display: grid;
    gap: var(--gap);
    grid-template-columns: repeat(var(--columns), minmax(0, 1fr));
    margin: 0;
    padding: 0; }
    .cs-image-teaser--items-in-row-mobile-2 .cs-image-teaser__slides {
      --columns: 2; }
  .cs-image-teaser__slide {
    display: inline-block;
    margin: 0; }
  .cs-image-teaser__link {
    outline-offset: calc(var(--gap) / 2 + 2); }
    .cs-image-teaser__link:focus {
      outline-offset: 0.4rem; }
    @media (prefers-reduced-motion: no-preference) {
      .cs-image-teaser__link {
        transition: outline-offset 0.25s ease; } }
  .cs-image-teaser__picture {
    display: block;
    user-select: none;
    overflow: hidden;
    width: 100%;
    height: auto;
    /* stylelint-disable -- stylelint doesn't know this media query yet. */
    /* stylelint-enable */ }
    @media (prefers-reduced-data: reduce) {
      .cs-image-teaser__picture {
        display: none; } }
  .cs-image-teaser__image {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    background-color: #f0f0f0; }
  @media (min-width: 48em) {
    .cs-image-teaser {
      --gap: 0.8rem; }
      .cs-image-teaser--items-in-row-2 .cs-image-teaser__slides {
        --columns: 2; }
      .cs-image-teaser--items-in-row-3 .cs-image-teaser__slides {
        --columns: 3; }
      .cs-image-teaser--items-in-row-4 .cs-image-teaser__slides {
        --columns: 4; }
      .cs-image-teaser--items-in-row-5 .cs-image-teaser__slides {
        --columns: 5; }
      .cs-image-teaser--items-in-row-6 .cs-image-teaser__slides {
        --columns: 6; }
      .cs-image-teaser--items-in-row-7 .cs-image-teaser__slides {
        --columns: 7; }
      .cs-image-teaser--items-in-row-8 .cs-image-teaser__slides {
        --columns: 8; } }

@media (min-width: 48em) and (max-width: 63.99em) {
  .cs-image-teaser--items-in-row-4:not(.cs-image-teaser--icon) .cs-image-teaser__slides {
    --columns: 2; } }
  .cs-image-teaser--slider {
    --gap: 0.8rem;
    --base-size: calc(100% - 0px);
    --size: var(--base-size);
    /**
     * class-based columns config relates to classnames, like '--items-in-row-4'
     * which are added to elements, depending on the CC Components configuration.
     */
    /*  container padding fix  */ }
    @media (min-width: 81.875em) {
      .cs-image-teaser--slider.cs-image-teaser--container-width {
        --base-size: calc(128rem - 0px); }
      .cs-image-teaser--slider.cs-image-teaser--use-whole-screen:not(.cs-image-teaser--full-width-slider) {
        --base-size: 100%;
        max-width: 100%; }
        .cs-image-teaser--slider.cs-image-teaser--use-whole-screen:not(.cs-image-teaser--full-width-slider) .cs-image-teaser__slides {
          scroll-snap-type: x proximity;
          padding-left: calc((100% - 128rem) / 2);
          padding-right: calc((100% - 128rem) / 2);
          scroll-padding-left: calc((100% - 128rem) / 2);
          scroll-padding-right: calc((100% - 128rem) / 2);
          scroll-margin-left: calc((100vw - 128rem) / 2);
          scroll-margin-right: calc((100vw - 128rem) / 2); } }
    @media (hover: hover) and (pointer: fine) and (min-width: 81.875em) {
      .cs-image-teaser--slider.cs-image-teaser--container-width {
        --base-size: 128rem; } }
    @media (min-width: 48em) {
      .cs-image-teaser--slider {
        --gap: 0.8rem; } }
    @media (min-width: 48em) {
      .cs-image-teaser--slider.cs-image-teaser--items-in-row-2 {
        --size: calc(
                        var(--base-size) /
                            2 -
                            ((1 * var(--gap)) / 2)
                    ); }
      .cs-image-teaser--slider.cs-image-teaser--items-in-row-3 {
        --size: calc(
                        var(--base-size) /
                            3 -
                            ((2 * var(--gap)) / 3)
                    ); }
      .cs-image-teaser--slider.cs-image-teaser--items-in-row-4 {
        --size: calc(
                        var(--base-size) /
                            4 -
                            ((3 * var(--gap)) / 4)
                    ); }
      .cs-image-teaser--slider.cs-image-teaser--items-in-row-5 {
        --size: calc(
                        var(--base-size) /
                            5 -
                            ((4 * var(--gap)) / 5)
                    ); }
      .cs-image-teaser--slider.cs-image-teaser--items-in-row-6 {
        --size: calc(
                        var(--base-size) /
                            6 -
                            ((5 * var(--gap)) / 6)
                    ); }
      .cs-image-teaser--slider.cs-image-teaser--items-in-row-7 {
        --size: calc(
                        var(--base-size) /
                            7 -
                            ((6 * var(--gap)) / 7)
                    ); }
      .cs-image-teaser--slider.cs-image-teaser--items-in-row-8 {
        --size: calc(
                        var(--base-size) /
                            8 -
                            ((7 * var(--gap)) / 8)
                    ); } }

@media (min-width: 48em) and (max-width: 63.99em) {
  .cs-image-teaser--slider.cs-image-teaser--items-in-row-4:not(.cs-image-teaser--icon) {
    --size: calc(var(--base-size) / 2 - (1 * var(--gap) / 2)); } }
    @media (max-width: 81.8125em) {
      .cs-image-teaser--slider.cs-image-teaser--container-width .cs-image-teaser__slides {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
        scroll-padding-left: 1.5rem;
        scroll-padding-right: 1.5rem; } }
    @media (prefers-reduced-motion: no-preference) {
      .cs-image-teaser--slider .cs-image-teaser__slides {
        scroll-behavior: smooth; } }
    .cs-image-teaser--slider .cs-image-teaser__slides-wrapper {
      position: relative; }
    .cs-image-teaser--slider .cs-image-teaser__slides {
      margin: 0;
      padding: 0;
      list-style: none;
      display: flex;
      flex: 0 0 100%;
      gap: 0;
      flex-wrap: nowrap;
      padding: var(--gap) 0;
      overflow-x: auto;
      overscroll-behavior-inline: contain;
      scrollbar-width: thin; }
      @supports (scroll-behavior: smooth) {
        .cs-image-teaser--slider .cs-image-teaser__slides {
          scroll-snap-type: x mandatory; } }
      @media (min-width: 48em) {
        .cs-image-teaser--slider .cs-image-teaser__slides {
          overflow: -moz-scrollbars-none;
          scrollbar-width: none; }
          .cs-image-teaser--slider .cs-image-teaser__slides::-webkit-scrollbar {
            display: none;
            width: 0;
            height: 0;
            background: transparent; }
          .cs-image-teaser--slider .cs-image-teaser__slides::-webkit-scrollbar-thumb {
            background: transparent;
            width: 0;
            height: 0; } }
    .cs-image-teaser--slider .cs-image-teaser__slide {
      margin: 0;
      padding: 0;
      list-style: none;
      display: inline-block;
      flex: 0 0 var(--size);
      margin-right: var(--gap);
      scroll-snap-align: start;
      min-width: 0; }
      .cs-image-teaser--slider .cs-image-teaser__slide:last-of-type {
        margin-right: 0; }
    .cs-image-teaser--slider .cs-image-teaser__image {
      display: block; }
  @media (max-width: 47.99em) {
    .cs-image-teaser--slider-mobile:not(.cs-image-teaser--slider) {
      --gap: 0.8rem;
      --base-size: calc(100% - 0px);
      --size: var(--base-size); }
      .cs-image-teaser--slider-mobile:not(.cs-image-teaser--slider).cs-image-teaser--container-width {
        margin-left: -1.5rem;
        margin-right: -1.5rem; }
        .cs-image-teaser--slider-mobile:not(.cs-image-teaser--slider).cs-image-teaser--container-width .cs-image-teaser__slides {
          padding-left: 1.5rem;
          padding-right: 1.5rem;
          scroll-padding-left: 1.5rem;
          scroll-padding-right: 1.5rem; } }
  @media (max-width: 47.99em) and (prefers-reduced-motion: no-preference) {
    .cs-image-teaser--slider-mobile:not(.cs-image-teaser--slider) .cs-image-teaser__slides {
      scroll-behavior: smooth; } }
  @media (max-width: 47.99em) {
      .cs-image-teaser--slider-mobile:not(.cs-image-teaser--slider) .cs-image-teaser__slides-wrapper {
        position: relative; }
      .cs-image-teaser--slider-mobile:not(.cs-image-teaser--slider) .cs-image-teaser__slides {
        margin: 0;
        padding: 0;
        list-style: none;
        display: flex;
        flex: 0 0 100%;
        gap: 0;
        flex-wrap: nowrap;
        padding: var(--gap) 0;
        overflow-x: auto;
        overscroll-behavior-inline: contain;
        scrollbar-width: thin; }
        @supports (scroll-behavior: smooth) {
          .cs-image-teaser--slider-mobile:not(.cs-image-teaser--slider) .cs-image-teaser__slides {
            scroll-snap-type: x mandatory; } } }
    @media (max-width: 47.99em) and (min-width: 48em) {
      .cs-image-teaser--slider-mobile:not(.cs-image-teaser--slider) .cs-image-teaser__slides {
        overflow: -moz-scrollbars-none;
        scrollbar-width: none; }
        .cs-image-teaser--slider-mobile:not(.cs-image-teaser--slider) .cs-image-teaser__slides::-webkit-scrollbar {
          display: none;
          width: 0;
          height: 0;
          background: transparent; }
        .cs-image-teaser--slider-mobile:not(.cs-image-teaser--slider) .cs-image-teaser__slides::-webkit-scrollbar-thumb {
          background: transparent;
          width: 0;
          height: 0; } }
  @media (max-width: 47.99em) {
      .cs-image-teaser--slider-mobile:not(.cs-image-teaser--slider) .cs-image-teaser__slide {
        margin: 0;
        padding: 0;
        list-style: none;
        display: inline-block;
        flex: 0 0 var(--size);
        margin-right: var(--gap);
        scroll-snap-align: start;
        min-width: 0; }
        .cs-image-teaser--slider-mobile:not(.cs-image-teaser--slider) .cs-image-teaser__slide:last-of-type {
          margin-right: 0; }
      .cs-image-teaser--slider-mobile:not(.cs-image-teaser--slider) .cs-image-teaser__image {
        display: block; } }
  @media (min-width: 48em) {
    .cs-image-teaser--slider-mobile:not(.cs-image-teaser--slider) .cs-image-teaser__nav,
    .cs-image-teaser--slider-mobile:not(.cs-image-teaser--slider) .cs-image-teaser__pagination {
      display: none; } }
  .cs-image-teaser__slide--scheme-light {
    --scheme-color: #fff;
    --scheme-background: #212121; }
  .cs-image-teaser__slide--scheme-dark {
    --scheme-color: #455a64;
    --scheme-background: #fff; }
  .cs-image-teaser__slide:not(.cs-image-teaser__slide--image-uploaded) {
    background: var(--scheme-background); }
  @media (max-width: 47.99em) {
    .cs-image-teaser__slides--mobile_text-image .cs-image-teaser__slide--image-uploaded {
      order: 1; }
    .cs-image-teaser__slides--mobile_image-text .cs-image-teaser__slide--image-uploaded {
      order: -1; } }
  @media (max-width: 47.99em) {
    .cs-image-teaser--as-list-mobile .cs-image-teaser__slides {
      flex-wrap: wrap; }
    .cs-image-teaser--as-list-mobile .cs-image-teaser__slide {
      margin: 0 0 0.8rem; }
    .cs-image-teaser--as-list-mobile .cs-image-teaser__pagination,
    .cs-image-teaser--as-list-mobile .cs-image-teaser__nav {
      display: none; } }
  .cs-image-teaser__link {
    display: block;
    height: 100%; }
    .cs-image-teaser__link, .cs-image-teaser__link:hover, .cs-image-teaser__link:visited, .cs-image-teaser__link:active, .cs-image-teaser__link:focus {
      text-decoration: none; }
  .cs-image-teaser__figure {
    height: 100%;
    position: relative; }
  .cs-image-teaser__content-wrapper {
    color: var(--scheme-color); }
    .cs-image-teaser__content-wrapper--under {
      position: relative;
      z-index: 1;
      margin: -2rem 2rem 0;
      padding: 2rem;
      background-color: var(--scheme-background);
      text-align: center; }
    .cs-image-teaser__content-wrapper--over {
      width: 100%;
      height: 100%;
      display: flex;
      padding: 2rem;
      overflow: hidden;
      align-items: flex-start; }
      .cs-image-teaser__picture + .cs-image-teaser__content-wrapper--over,
      .cs-image-teaser--teaser-and-text .cs-image-teaser__slide--image-uploaded .cs-image-teaser__content-wrapper--over {
        position: absolute;
        top: 0;
        left: 0; }
    .cs-image-teaser__content-wrapper--content-align-x-2 {
      justify-content: center;
      text-align: center; }
    .cs-image-teaser__content-wrapper--content-align-x-3 {
      justify-content: flex-end;
      text-align: right; }
    .cs-image-teaser__content-wrapper--content-align-y-2 {
      align-items: center; }
    .cs-image-teaser__content-wrapper--content-align-y-3 {
      align-items: flex-end; }
  .cs-image-teaser__content {
    position: relative; }
  .cs-image-teaser__text-content + .cs-image-teaser__cta {
    margin-top: 1em !important; }
  .cs-image-teaser__slogan {
    margin: 0;
    line-height: 1.1;
    font-size: var(--slogan-font-size);
    font-weight: 900;
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;
    hyphens: auto; }
  .cs-image-teaser__description {
    margin: 1em 0 0;
    line-height: 1.1;
    font-size: var(--description-font-size); }
  .cs-image-teaser__cta {
    display: none; }
    .cs-image-teaser__slide--scheme-dark .cs-image-teaser__cta {
      display: inline-block;
      font-size: 1.8rem;
      font-weight: 700;
      text-align: center;
      position: relative;
      line-height: 1.275em;
      margin: 0;
      text-transform: none;
      padding: 0.75em 1em;
      box-sizing: border-box;
      transition: background-color 0.5s, border-color 0.5s, fill 0.5s, color 0.5s;
      vertical-align: middle;
      z-index: 5; }
      .cs-image-teaser__slide--scheme-dark .cs-image-teaser__cta:hover, .cs-image-teaser__slide--scheme-dark .cs-image-teaser__cta:focus, .cs-image-teaser__slide--scheme-dark .cs-image-teaser__cta:active {
        background-color: #7e57c2 !important; }
      .cs-image-teaser__slide--scheme-dark .cs-image-teaser__cta:before, .cs-image-teaser__slide--scheme-dark .cs-image-teaser__cta:after {
        content: '';
        position: absolute;
        top: -1px;
        bottom: -1px;
        right: -1px; }
      .cs-image-teaser__slide--scheme-dark .cs-image-teaser__cta:before {
        width: 3.4em;
        border-radius: 0 200px 200px 0;
        z-index: -1;
        background: linear-gradient(0deg, transparent 0%, transparent 0%, #4d2c91 0%, #4d2c91 100%); }
      .cs-image-teaser__slide--scheme-dark .cs-image-teaser__cta:after {
        width: 3em;
        min-width: auto;
        background-color: #4d2c91;
        transition: width 0.25s linear;
        z-index: -2;
        border-radius: 200px; }
      .cs-image-teaser__slide--scheme-dark .cs-image-teaser__cta:hover:after {
        width: calc(100% + 2px); }
      .cs-image-teaser__slide--scheme-dark .cs-image-teaser__cta::-moz-focus-inner {
        border: 0;
        padding: 0;
        margin: 0; }
      .cs-image-teaser__slide--scheme-dark .cs-image-teaser__cta, .cs-image-teaser__slide--scheme-dark .cs-image-teaser__cta:visited {
        cursor: pointer;
        border-radius: 200px;
        outline-offset: 1px;
        text-decoration: none;
        box-shadow: none;
        border: 1px solid transparent;
        color: #fff;
        background-color: #7e57c2; }
        .cs-image-teaser__slide--scheme-dark .cs-image-teaser__cta *, .cs-image-teaser__slide--scheme-dark .cs-image-teaser__cta:visited * {
          color: #fff;
          fill: #fff; }
      .cs-image-teaser__slide--scheme-dark .cs-image-teaser__cta:hover:not([disabled]), .cs-image-teaser__slide--scheme-dark .cs-image-teaser__cta:focus:not([disabled]), .cs-image-teaser__slide--scheme-dark .cs-image-teaser__cta:active:not([disabled]) {
        box-shadow: none;
        outline: none;
        text-decoration: none;
        border: 1px solid transparent;
        color: #fff;
        background-color: #4d2c91; }
        .cs-image-teaser__slide--scheme-dark .cs-image-teaser__cta:hover:not([disabled]) *, .cs-image-teaser__slide--scheme-dark .cs-image-teaser__cta:focus:not([disabled]) *, .cs-image-teaser__slide--scheme-dark .cs-image-teaser__cta:active:not([disabled]) * {
          color: #fff;
          fill: #fff; }
      .cs-image-teaser__slide--scheme-dark .cs-image-teaser__cta[disabled] {
        opacity: 0.65;
        cursor: auto; }
    .cs-image-teaser__slide--scheme-light .cs-image-teaser__cta {
      display: inline-block;
      font-size: 1.8rem;
      font-weight: 700;
      text-align: center;
      position: relative;
      line-height: 1.275em;
      margin: 0;
      text-transform: none;
      padding: 0.75em 1em;
      box-sizing: border-box;
      transition: background-color 0.5s, border-color 0.5s, fill 0.5s, color 0.5s;
      vertical-align: middle;
      z-index: 5; }
      .cs-image-teaser__slide--scheme-light .cs-image-teaser__cta::before, .cs-image-teaser__slide--scheme-light .cs-image-teaser__cta::after {
        content: none; }
      .cs-image-teaser__slide--scheme-light .cs-image-teaser__cta::-moz-focus-inner {
        border: 0;
        padding: 0;
        margin: 0; }
      .cs-image-teaser__slide--scheme-light .cs-image-teaser__cta, .cs-image-teaser__slide--scheme-light .cs-image-teaser__cta:visited {
        cursor: pointer;
        border-radius: 200px;
        outline-offset: 1px;
        text-decoration: none;
        box-shadow: none;
        border: 1px solid #7e57c2;
        color: #7e57c2;
        background-color: #fff; }
        .cs-image-teaser__slide--scheme-light .cs-image-teaser__cta *, .cs-image-teaser__slide--scheme-light .cs-image-teaser__cta:visited * {
          color: #7e57c2;
          fill: #7e57c2; }
      .cs-image-teaser__slide--scheme-light .cs-image-teaser__cta:hover:not([disabled]), .cs-image-teaser__slide--scheme-light .cs-image-teaser__cta:focus:not([disabled]), .cs-image-teaser__slide--scheme-light .cs-image-teaser__cta:active:not([disabled]) {
        box-shadow: none;
        outline: none;
        text-decoration: none;
        border: 1px solid #4d2c91;
        color: #fff;
        background-color: #4d2c91; }
        .cs-image-teaser__slide--scheme-light .cs-image-teaser__cta:hover:not([disabled]) *, .cs-image-teaser__slide--scheme-light .cs-image-teaser__cta:focus:not([disabled]) *, .cs-image-teaser__slide--scheme-light .cs-image-teaser__cta:active:not([disabled]) * {
          color: #fff;
          fill: #fff; }
      .cs-image-teaser__slide--scheme-light .cs-image-teaser__cta[disabled] {
        opacity: 0.65;
        cursor: auto; }
    .cs-image-teaser__slide--scheme-dark .cs-image-teaser__cta-span {
      margin-top: 0;
      z-index: 1;
      font-weight: 700;
      vertical-align: baseline;
      display: inline-block;
      line-height: 1.275em;
      letter-spacing: normal;
      padding-left: 0;
      padding-right: 4em; }
    .cs-image-teaser__slide--scheme-light .cs-image-teaser__cta-span {
      margin-top: 0;
      z-index: 1;
      font-weight: 700;
      vertical-align: baseline;
      display: inline-block;
      line-height: 1.275em;
      letter-spacing: normal;
      padding-left: 0;
      padding-right: 4em; }
    .cs-image-teaser__slide--scheme-dark .cs-image-teaser__cta-icon {
      max-width: 1em;
      max-height: 1.8rem;
      z-index: 2;
      height: auto;
      width: 100%;
      position: absolute;
      top: 50%;
      transform: translate3d(0, -50%, 0);
      margin-right: 0;
      right: 1em; }
    .cs-image-teaser__slide--scheme-light .cs-image-teaser__cta-icon {
      max-width: 1em;
      max-height: 1.8rem;
      z-index: 2;
      height: auto;
      width: 100%;
      position: absolute;
      top: 50%;
      transform: translate3d(0, -50%, 0);
      margin-right: 0;
      right: 1em; }
    @media (min-width: 30em) {
      .cs-image-teaser__cta {
        display: inline-flex;
        z-index: 1;
        margin: 1.2em 0 0;
        align-items: center;
        font-size: 1.2rem; }
        .cs-image-teaser__cta-icon {
          width: 1.2rem;
          height: 1.4rem; } }
  .cs-image-teaser__overlay, .cs-image-teaser__gradient {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }
  .cs-image-teaser__slide--scheme-light .cs-image-teaser__overlay, .cs-image-teaser__slide--scheme-light .cs-image-teaser__optimizer-container {
    background-color: #212121; }
  .cs-image-teaser__slide--scheme-dark .cs-image-teaser__overlay, .cs-image-teaser__slide--scheme-dark .cs-image-teaser__optimizer-container {
    background-color: #fff; }
  .cs-image-teaser__slide--scheme-light .cs-image-teaser__gradient--direction-x-1.cs-image-teaser__gradient--direction-y-1 {
    background: linear-gradient(135deg, #212121 0%, rgba(33, 33, 33, 0) 85%); }
  .cs-image-teaser__slide--scheme-dark .cs-image-teaser__gradient--direction-x-1.cs-image-teaser__gradient--direction-y-1 {
    background: linear-gradient(135deg, #fff 0%, rgba(255, 255, 255, 0) 85%); }
  .cs-image-teaser__slide--scheme-light .cs-image-teaser__gradient--direction-x-1.cs-image-teaser__gradient--direction-y-2 {
    background: linear-gradient(90deg, #212121 0%, rgba(33, 33, 33, 0) 85%); }
  .cs-image-teaser__slide--scheme-dark .cs-image-teaser__gradient--direction-x-1.cs-image-teaser__gradient--direction-y-2 {
    background: linear-gradient(90deg, #fff 0%, rgba(255, 255, 255, 0) 85%); }
  .cs-image-teaser__slide--scheme-light .cs-image-teaser__gradient--direction-x-1.cs-image-teaser__gradient--direction-y-3 {
    background: linear-gradient(45deg, #212121 0%, rgba(33, 33, 33, 0) 85%); }
  .cs-image-teaser__slide--scheme-dark .cs-image-teaser__gradient--direction-x-1.cs-image-teaser__gradient--direction-y-3 {
    background: linear-gradient(45deg, #fff 0%, rgba(255, 255, 255, 0) 85%); }
  .cs-image-teaser__slide--scheme-light .cs-image-teaser__gradient--direction-x-2.cs-image-teaser__gradient--direction-y-1 {
    background: linear-gradient(180deg, #212121 0%, rgba(33, 33, 33, 0) 85%); }
  .cs-image-teaser__slide--scheme-dark .cs-image-teaser__gradient--direction-x-2.cs-image-teaser__gradient--direction-y-1 {
    background: linear-gradient(180deg, #fff 0%, rgba(255, 255, 255, 0) 85%); }
  .cs-image-teaser__slide--scheme-light .cs-image-teaser__gradient--direction-x-2.cs-image-teaser__gradient--direction-y-2 {
    background: linear-gradient(90deg, #212121 0%, rgba(33, 33, 33, 0) 85%); }
  .cs-image-teaser__slide--scheme-dark .cs-image-teaser__gradient--direction-x-2.cs-image-teaser__gradient--direction-y-2 {
    background: linear-gradient(90deg, #fff 0%, rgba(255, 255, 255, 0) 85%); }
  .cs-image-teaser__slide--scheme-light .cs-image-teaser__gradient--direction-x-2.cs-image-teaser__gradient--direction-y-3 {
    background: linear-gradient(0deg, #212121 0%, rgba(33, 33, 33, 0) 85%); }
  .cs-image-teaser__slide--scheme-dark .cs-image-teaser__gradient--direction-x-2.cs-image-teaser__gradient--direction-y-3 {
    background: linear-gradient(0deg, #fff 0%, rgba(255, 255, 255, 0) 85%); }
  .cs-image-teaser__slide--scheme-light .cs-image-teaser__gradient--direction-x-3.cs-image-teaser__gradient--direction-y-1 {
    background: linear-gradient(-135deg, #212121 0%, rgba(33, 33, 33, 0) 85%); }
  .cs-image-teaser__slide--scheme-dark .cs-image-teaser__gradient--direction-x-3.cs-image-teaser__gradient--direction-y-1 {
    background: linear-gradient(-135deg, #fff 0%, rgba(255, 255, 255, 0) 85%); }
  .cs-image-teaser__slide--scheme-light .cs-image-teaser__gradient--direction-x-3.cs-image-teaser__gradient--direction-y-2 {
    background: linear-gradient(-90deg, #212121 0%, rgba(33, 33, 33, 0) 85%); }
  .cs-image-teaser__slide--scheme-dark .cs-image-teaser__gradient--direction-x-3.cs-image-teaser__gradient--direction-y-2 {
    background: linear-gradient(-90deg, #fff 0%, rgba(255, 255, 255, 0) 85%); }
  .cs-image-teaser__slide--scheme-light .cs-image-teaser__gradient--direction-x-3.cs-image-teaser__gradient--direction-y-3 {
    background: linear-gradient(-45deg, #212121 0%, rgba(33, 33, 33, 0) 85%); }
  .cs-image-teaser__slide--scheme-dark .cs-image-teaser__gradient--direction-x-3.cs-image-teaser__gradient--direction-y-3 {
    background: linear-gradient(-45deg, #fff 0%, rgba(255, 255, 255, 0) 85%); }
  .cs-image-teaser__image--mirror {
    transform: scaleX(-1); }
  .cs-image-teaser__content--container {
    position: relative;
    z-index: 1;
    padding: 2em; }
  .cs-image-teaser__optimizer-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1; }
  .cs-image-teaser__slide--scheme-dark .cs-image-teaser__text-content--text-shadow-0 {
    text-shadow: 0 0 2.5rem rgba(255, 255, 255, 0); }
  .cs-image-teaser__slide--scheme-dark .cs-image-teaser__text-content--text-shadow-5 {
    text-shadow: 0 0 2.5rem rgba(255, 255, 255, 0.05); }
  .cs-image-teaser__slide--scheme-dark .cs-image-teaser__text-content--text-shadow-10 {
    text-shadow: 0 0 2.5rem rgba(255, 255, 255, 0.1); }
  .cs-image-teaser__slide--scheme-dark .cs-image-teaser__text-content--text-shadow-15 {
    text-shadow: 0 0 2.5rem rgba(255, 255, 255, 0.15); }
  .cs-image-teaser__slide--scheme-dark .cs-image-teaser__text-content--text-shadow-20 {
    text-shadow: 0 0 2.5rem rgba(255, 255, 255, 0.2); }
  .cs-image-teaser__slide--scheme-dark .cs-image-teaser__text-content--text-shadow-25 {
    text-shadow: 0 0 2.5rem rgba(255, 255, 255, 0.25); }
  .cs-image-teaser__slide--scheme-dark .cs-image-teaser__text-content--text-shadow-30 {
    text-shadow: 0 0 2.5rem rgba(255, 255, 255, 0.3); }
  .cs-image-teaser__slide--scheme-dark .cs-image-teaser__text-content--text-shadow-35 {
    text-shadow: 0 0 2.5rem rgba(255, 255, 255, 0.35); }
  .cs-image-teaser__slide--scheme-dark .cs-image-teaser__text-content--text-shadow-40 {
    text-shadow: 0 0 2.5rem rgba(255, 255, 255, 0.4); }
  .cs-image-teaser__slide--scheme-dark .cs-image-teaser__text-content--text-shadow-45 {
    text-shadow: 0 0 2.5rem rgba(255, 255, 255, 0.45); }
  .cs-image-teaser__slide--scheme-dark .cs-image-teaser__text-content--text-shadow-50 {
    text-shadow: 0 0 2.5rem rgba(255, 255, 255, 0.5); }
  .cs-image-teaser__slide--scheme-dark .cs-image-teaser__text-content--text-shadow-55 {
    text-shadow: 0 0 2.5rem rgba(255, 255, 255, 0.55); }
  .cs-image-teaser__slide--scheme-dark .cs-image-teaser__text-content--text-shadow-60 {
    text-shadow: 0 0 2.5rem rgba(255, 255, 255, 0.6); }
  .cs-image-teaser__slide--scheme-dark .cs-image-teaser__text-content--text-shadow-65 {
    text-shadow: 0 0 2.5rem rgba(255, 255, 255, 0.65); }
  .cs-image-teaser__slide--scheme-dark .cs-image-teaser__text-content--text-shadow-70 {
    text-shadow: 0 0 2.5rem rgba(255, 255, 255, 0.7); }
  .cs-image-teaser__slide--scheme-dark .cs-image-teaser__text-content--text-shadow-75 {
    text-shadow: 0 0 2.5rem rgba(255, 255, 255, 0.75); }
  .cs-image-teaser__slide--scheme-dark .cs-image-teaser__text-content--text-shadow-80 {
    text-shadow: 0 0 2.5rem rgba(255, 255, 255, 0.8); }
  .cs-image-teaser__slide--scheme-dark .cs-image-teaser__text-content--text-shadow-85 {
    text-shadow: 0 0 2.5rem rgba(255, 255, 255, 0.85); }
  .cs-image-teaser__slide--scheme-dark .cs-image-teaser__text-content--text-shadow-90 {
    text-shadow: 0 0 2.5rem rgba(255, 255, 255, 0.9); }
  .cs-image-teaser__slide--scheme-dark .cs-image-teaser__text-content--text-shadow-95 {
    text-shadow: 0 0 2.5rem rgba(255, 255, 255, 0.95); }
  .cs-image-teaser__slide--scheme-dark .cs-image-teaser__text-content--text-shadow-100 {
    text-shadow: 0 0 2.5rem white; }
  .cs-image-teaser__slide--scheme-light .cs-image-teaser__text-content--text-shadow-0 {
    text-shadow: 0 0 2.5rem rgba(33, 33, 33, 0); }
  .cs-image-teaser__slide--scheme-light .cs-image-teaser__text-content--text-shadow-5 {
    text-shadow: 0 0 2.5rem rgba(33, 33, 33, 0.05); }
  .cs-image-teaser__slide--scheme-light .cs-image-teaser__text-content--text-shadow-10 {
    text-shadow: 0 0 2.5rem rgba(33, 33, 33, 0.1); }
  .cs-image-teaser__slide--scheme-light .cs-image-teaser__text-content--text-shadow-15 {
    text-shadow: 0 0 2.5rem rgba(33, 33, 33, 0.15); }
  .cs-image-teaser__slide--scheme-light .cs-image-teaser__text-content--text-shadow-20 {
    text-shadow: 0 0 2.5rem rgba(33, 33, 33, 0.2); }
  .cs-image-teaser__slide--scheme-light .cs-image-teaser__text-content--text-shadow-25 {
    text-shadow: 0 0 2.5rem rgba(33, 33, 33, 0.25); }
  .cs-image-teaser__slide--scheme-light .cs-image-teaser__text-content--text-shadow-30 {
    text-shadow: 0 0 2.5rem rgba(33, 33, 33, 0.3); }
  .cs-image-teaser__slide--scheme-light .cs-image-teaser__text-content--text-shadow-35 {
    text-shadow: 0 0 2.5rem rgba(33, 33, 33, 0.35); }
  .cs-image-teaser__slide--scheme-light .cs-image-teaser__text-content--text-shadow-40 {
    text-shadow: 0 0 2.5rem rgba(33, 33, 33, 0.4); }
  .cs-image-teaser__slide--scheme-light .cs-image-teaser__text-content--text-shadow-45 {
    text-shadow: 0 0 2.5rem rgba(33, 33, 33, 0.45); }
  .cs-image-teaser__slide--scheme-light .cs-image-teaser__text-content--text-shadow-50 {
    text-shadow: 0 0 2.5rem rgba(33, 33, 33, 0.5); }
  .cs-image-teaser__slide--scheme-light .cs-image-teaser__text-content--text-shadow-55 {
    text-shadow: 0 0 2.5rem rgba(33, 33, 33, 0.55); }
  .cs-image-teaser__slide--scheme-light .cs-image-teaser__text-content--text-shadow-60 {
    text-shadow: 0 0 2.5rem rgba(33, 33, 33, 0.6); }
  .cs-image-teaser__slide--scheme-light .cs-image-teaser__text-content--text-shadow-65 {
    text-shadow: 0 0 2.5rem rgba(33, 33, 33, 0.65); }
  .cs-image-teaser__slide--scheme-light .cs-image-teaser__text-content--text-shadow-70 {
    text-shadow: 0 0 2.5rem rgba(33, 33, 33, 0.7); }
  .cs-image-teaser__slide--scheme-light .cs-image-teaser__text-content--text-shadow-75 {
    text-shadow: 0 0 2.5rem rgba(33, 33, 33, 0.75); }
  .cs-image-teaser__slide--scheme-light .cs-image-teaser__text-content--text-shadow-80 {
    text-shadow: 0 0 2.5rem rgba(33, 33, 33, 0.8); }
  .cs-image-teaser__slide--scheme-light .cs-image-teaser__text-content--text-shadow-85 {
    text-shadow: 0 0 2.5rem rgba(33, 33, 33, 0.85); }
  .cs-image-teaser__slide--scheme-light .cs-image-teaser__text-content--text-shadow-90 {
    text-shadow: 0 0 2.5rem rgba(33, 33, 33, 0.9); }
  .cs-image-teaser__slide--scheme-light .cs-image-teaser__text-content--text-shadow-95 {
    text-shadow: 0 0 2.5rem rgba(33, 33, 33, 0.95); }
  .cs-image-teaser__slide--scheme-light .cs-image-teaser__text-content--text-shadow-100 {
    text-shadow: 0 0 2.5rem #212121; }

@media (min-width: 48em) and (max-width: 63.99em) {
  .cs-image-teaser--icon {
    --gap: 0.8rem;
    --base-size: calc(100% - 0px);
    --size: var(--base-size); }
    .cs-image-teaser--icon.cs-image-teaser--container-width {
      margin-left: -1.5rem;
      margin-right: -1.5rem; }
      .cs-image-teaser--icon.cs-image-teaser--container-width .cs-image-teaser__slides {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
        scroll-padding-left: 1.5rem;
        scroll-padding-right: 1.5rem; } }
  @media (min-width: 48em) and (max-width: 63.99em) and (prefers-reduced-motion: no-preference) {
    .cs-image-teaser--icon .cs-image-teaser__slides {
      scroll-behavior: smooth; } }

@media (min-width: 48em) and (max-width: 63.99em) {
    .cs-image-teaser--icon .cs-image-teaser__slides-wrapper {
      position: relative; }
    .cs-image-teaser--icon .cs-image-teaser__slides {
      margin: 0;
      padding: 0;
      list-style: none;
      display: flex;
      flex: 0 0 100%;
      gap: 0;
      flex-wrap: nowrap;
      padding: var(--gap) 0;
      overflow-x: auto;
      overscroll-behavior-inline: contain;
      scrollbar-width: thin; }
      @supports (scroll-behavior: smooth) {
        .cs-image-teaser--icon .cs-image-teaser__slides {
          scroll-snap-type: x mandatory; } } }
    @media (min-width: 48em) and (max-width: 63.99em) and (min-width: 48em) {
      .cs-image-teaser--icon .cs-image-teaser__slides {
        overflow: -moz-scrollbars-none;
        scrollbar-width: none; }
        .cs-image-teaser--icon .cs-image-teaser__slides::-webkit-scrollbar {
          display: none;
          width: 0;
          height: 0;
          background: transparent; }
        .cs-image-teaser--icon .cs-image-teaser__slides::-webkit-scrollbar-thumb {
          background: transparent;
          width: 0;
          height: 0; } }

@media (min-width: 48em) and (max-width: 63.99em) {
    .cs-image-teaser--icon .cs-image-teaser__slide {
      margin: 0;
      padding: 0;
      list-style: none;
      display: inline-block;
      flex: 0 0 var(--size);
      margin-right: var(--gap);
      scroll-snap-align: start;
      min-width: 0; }
      .cs-image-teaser--icon .cs-image-teaser__slide:last-of-type {
        margin-right: 0; }
    .cs-image-teaser--icon .cs-image-teaser__image {
      display: block; } }

@media (min-width: 0px) and (max-width: 63.99em) {
  .cs-image-teaser--icon {
    --size: calc(
                    var(--base-size) / 2 - ((
                                    1 * var(--gap)
                                ) / 2)
                ) !important; } }

@media (min-width: 23.125em) and (max-width: 63.99em) {
  .cs-image-teaser--icon {
    --size: calc(
                    var(--base-size) / 2 - ((
                                    1 * var(--gap)
                                ) / 2)
                ) !important; } }

@media (min-width: 30em) and (max-width: 63.99em) {
  .cs-image-teaser--icon {
    --size: calc(
                    var(--base-size) / 2 - ((
                                    1 * var(--gap)
                                ) / 2)
                ) !important; } }

@media (min-width: 48em) and (max-width: 63.99em) {
  .cs-image-teaser--icon {
    --size: calc(
                    var(--base-size) / 3 - ((
                                    2 * var(--gap)
                                ) / 3)
                ) !important; } }

@media (min-width: 48em) and (max-width: 63.99em) and (hover: hover) and (pointer: fine) {
  .cs-image-teaser--icon.cs-image-teaser--slider-mobile:not(.cs-image-teaser--slider) .cs-image-teaser__nav {
    display: block; } }

@media (min-width: 48em) and (max-width: 63.99em) {
  .cs-image-teaser--icon.cs-image-teaser--slider-mobile:not(.cs-image-teaser--slider) .cs-image-teaser__pagination {
    display: flex; } }
  .cs-image-teaser--icon .cs-image-teaser__slogan {
    --slogan-font-size: 1.6rem; }
  .cs-image-teaser--icon .cs-image-teaser__description {
    margin-top: 0.5em; }
  .cs-image-teaser--icon .cs-image-teaser__cta {
    display: none; }
  .cs-image-teaser--icon .cs-image-teaser__content-wrapper--under {
    margin-top: 0;
    padding: 1rem; }
  .cs-image-teaser--icon .cs-image-teaser__content-wrapper--cta-only {
    display: none; }
  @media (min-width: 48em) {
    .cs-image-teaser--mosaic .cs-image-teaser__slides--mosaic-scenario-1 {
      grid-template-columns: minmax(0, 2fr) minmax(0, 1fr); }
    .cs-image-teaser--mosaic .cs-image-teaser__slides--mosaic-scenario-2 {
      grid-template-columns: minmax(0, 1fr) minmax(0, 2fr); } }
  @media (max-width: 47.99em) {
    .cs-image-teaser--teaser-and-text .cs-image-teaser__slides {
      display: flex;
      flex-wrap: wrap; }
    .cs-image-teaser--teaser-and-text .cs-image-teaser__slide {
      flex: 0 0 100%; } }

.cs-container--image-teaser {
  margin: 0 0 3rem; }
  @media (min-width: 48em) {
    .cs-container--image-teaser {
      margin: 0 0 4rem; } }
  .cs-container--image-teaser--skip-spacing {
    margin: 0; }

.cs-container--grid-teaser {
  margin: 0; }

.cs-container--image-teaser-window-width {
  padding-left: 0;
  padding-right: 0; }
  .cs-container--image-teaser-window-width .cs-container__inner {
    max-width: 100%; }

.cs-container--image-teaser-slider {
  padding-left: 0;
  padding-right: 0; }
